.footer-container{
    background-color: #606060;
    display: flex;
    align-items: center;   
    flex-direction: column;
}
.main-footer{
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-logo{
    height: 6vh;
    filter: invert(1);
    margin: auto;
}
.social-link-list{
    margin-top: 10px;
    list-style: none;
    display: flex;
    justify-content: center;
}
.social-link-img{
    height: 20px;
    margin: 0 15px;
}
.footer-contact{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.contact-item{
    margin: 0 30px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.contact-icon{
    height: 20px;
    margin: 0 10px;
}
.contact-para{
    color: #fff;
}
.login-button{
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 20px;
    margin: 10px 0;
    cursor: pointer;
}
.copyright-container{
    box-shadow: 0px 2px 8px 0px #000;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.copyright-para{
    color: #fff;
}

@media only screen and (min-width: 500px) and (max-width:1024px){
    .main-footer{
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact-item{
        margin: 0 15px;
        display: flex;
        align-items: center;
    }

}

@media only screen and (min-width: 0px) and (max-width: 500px){
    .main-footer{
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0;
    }
    .contact-item{
        margin: 10px 0;
        display: flex;
        align-items: center;
    }

}