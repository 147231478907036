.admincontainer{
    margin: 30px 60px;
}
.req-list{
    display: flex;
    list-style: none;
    width: 100%;
    justify-content: space-around;
}
.req-btn{
    padding: 5px 10px;
    cursor: pointer;
}

/* subpage css */
.form-container{
    margin: 30px 60px;
    min-height: 60vh;
    width: 950px;
    margin: 0 auto;
}
.admin-form{
    display: flex;
    flex-direction: column;
}
.form-feild-file{
    display: flex;
    align-items: center;
}
.admin-form label{
    font-size: x-large;
    margin-left: 10px;
}
.admin-form input{
    margin-top: 10px;
    padding: 5px;
    margin-left: 10px;
}
.submit-btn{
    padding: 5px 20px;
    margin: 15px 15px;
}
.admin-form textarea{
    margin-top: 10px;
    padding: 5px;
    margin-left: 10px;

}
.delete-icon{
    align-items: center;
    height: 4vh;
    margin-top: 5px;
    margin-left: 20px;
}
.delete-icon:first-child{
    margin-left: 0;
}
.del-icon-container{
    position: absolute;
    display: none;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    background-color: #00000080; 
}
.album-img-container:hover>.del-icon-container{
    display: flex;
    cursor: pointer;
}
.filter{
    margin-left: 60px;
    padding-bottom:  0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-icon{
    height: 10vh;
}