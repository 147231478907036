.login-form-container{
    width: 100%;
    padding: 120px 0;
    display: flex;
    justify-content: center;
    min-height: 40vh;
}
.login-inner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.form-heading{

}
.login-form{
    display: flex;
    flex-direction: column;
}
.login-form>label{
    margin-bottom: 5px;
}
.login-form>input{
    margin-bottom: 30px;
    padding: 5px;
}