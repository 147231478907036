nav{
    display: flex;
    height: 10vh;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -3px 10px 0px black;
}
.logo{
    height: 6vh;
    margin-left: 120px;
    
}
.menu-list{
    display: flex;
    list-style: none;
    margin-right: 120px;
}
.menu-list li{
    padding: 0.75vh 0;
    /* position: relative; */
}
.menu-list-item:hover{
    font-weight: bold;
    transition: all 200ms ease-in;
}

.menu-list-item{
    margin: 0.25vh 2vw;
    text-decoration: none;
    text-transform: uppercase;
    color: black;
    position: relative;
}
.menu-btn-icon{
    display: none;
}
.menu-list-item::after{
    content: '';
    width: 0;
    height: 2px;
    background-color: black;
    position: absolute;
    bottom: -5px;
    left: 0;
}
.menu-list-item:hover.menu-list-item::after{
    width: 100%;
    transition: all 400ms ease-in; 
}
/* .sub-menu{
    width: 350px;
    height: 150px;
    position: absolute;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 4px 10px -4px black;
    top: 35px;
    padding: 1vh 1vw;
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
}
.sub-menu li>.menu-list-item{
    margin: 0.25vh .5vw;
}
.menu-list li:hover>.sub-menu{
    display: flex;
} */


@media only screen and (min-width: 500px) and (max-width:1024px){
    .logo{
        margin-left: 30px;       
    }
    .menu-list{
        margin-right: 60px;
    }
    .menu-list li{
        padding: 10px 0;
    }
    .menu-list-item:hover , .active{
        font-weight: bold;
        transition: all 200ms ease-in;
    }
    /* .dropdown-icon{
        display: inline-block;
        height: 10px;
    } */
    /* .sub-menu{
        width: 160px;
        height: auto;
        top: 45px;   
        padding: 10px 30px;
        display: none;
        flex-wrap: nowrap;
    }
    .sub-menu li>.menu-list-item{
        margin: 10px;
    }
    .menu-list li:hover>.sub-menu{
        display: flex;
    } */

}


@media only screen and (min-width: 0px) and (max-width: 500px){
    nav{
        height: 70px;
        justify-content: space-between;
        position: relative;
        overflow-x: hidden;
    }
    nav.menu-active{
        overflow-x: visible;
    }
    .logo{
        margin-left: 20px;
    }
    .menu-btn-icon{
        display: block;
        height: 30px;
        margin-right: 10px;
    }
    .menu-container{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        position: absolute;  
        top: 70px;      
        background-color:black;
        transform: translateX(100%);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: all 400ms ease-in;
        z-index: 3;
    }
    .menu-container.active{
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .menu-list{
        flex-direction: column;
        margin-right: 0;
    }
    .menu-list li{
        margin: 35px 0;
        display: flex;
        justify-content: center;
    }
    .menu-list li:first-child{
        margin-top: 70px;
    }
    .menu-list-item{
        color: #fff;
        font-size: 30px;
        text-align: center;

    }
    .menu-list-item::after{
        bottom: -10px;
        background-color: #fff;
    }
}