.albums-container{
    margin: 30px 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.album-link{
    text-decoration: none;
}
.album-card{
    margin: 30px 0;
}
.album-card-img-container{
    width: 25vw;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.album-card-img{
    width: 20vw;
}
.album-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.album-title{
    margin-top: 10px;
    width: 20vw;
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    color: var(--highlighted-text-color);
}
.album-card:nth-child(7){
    width: 100%;
}
.album-card:nth-child(7)>.album-card-img-container{
    width: 100%;
    height: 50vh;
}
.album-card:nth-child(7)>.album-card-img-container>.album-card-img{

    height: 60vh;
}
.album-card:nth-child(7)>.album-title-container>.album-title{
    width: 85vw;
}

@media only screen and (min-width: 500px) and (max-width:1024px){
    .albums-container{
        margin: 20px 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .album-card{
        margin: 10px 2vw;
    }
    .album-card-img-container{
        width: 25vw;
        height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .album-title{
        margin-top: 10px;
        width: 20vw;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        color: var(--highlighted-text-color);
    }
    .album-card:nth-child(7){
        width: 100%;
        margin: 0;
    }
    .album-card:nth-child(7)>.album-card-img-container{
        width: 100%;
        height: 30vh;
    }
    .album-card:nth-child(7)>.album-card-img-container>.album-card-img{
    
        height: 30vh;
    }
    .album-card:nth-child(7)>.album-title-container>.album-title{
        width: 85vw;
    }


}


@media only screen and (min-width: 0px) and (max-width: 500px){
    .albums-container{
        margin: 10px 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .album-card{
        margin: 10px 2vw;
    }
    .album-card-img-container{
        width: 40vw;
        height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .album-card-img{
        min-width: 100%;
        min-height: 100%;
    }
    .album-card:nth-child(7)>.album-card-img-container{
        width: 100%;
        height: 30vh;
    }
    .album-title{
        margin-top: 10px;
        width: 40vw;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: var(--highlighted-text-color);
    }
    .album-card:nth-child(7){
        width: 100%;
        margin: 0;
     
    }
    .album-card:nth-child(7)>.album-card-img-container{
        width: 100%;
        height: 20vh;
        align-items: center;

    }
    .album-card:nth-child(7)>.album-card-img-container>.album-card-img{ 
        height: 15vh;
    }
    .album-card:nth-child(7)>.album-title-container>.album-title{
        width: 85vw;
    }
    


}
