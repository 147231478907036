.work-body{
    display: flex;
    flex-direction: column;
    margin: 30px 60px;
}
.hero-para{
    font-size: 30px;
    width: 80%;
    font-weight: 100;
}
.work-card-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.work-card{
    margin: 30px 0;
}
.work-img-container{
    width: 28vw;
    height: 40vh;
    display: flex;
    align-items: center; 
    justify-content: center;
    overflow: hidden;
}
.work-img{
    object-fit: cover;
    min-width: 28vw;
    min-height: 40vh;
}
.work-img:hover{
    transform: scale(.9);
    transition: all 700ms ease-in;
    cursor: pointer;
}
.work-card:nth-child(7){
    width: 100%; 
}
.work-card:nth-child(7)>.link>.work-img-container{
    width: 100%;
    height: 70vh;
}
.work-card:nth-child(7)>.link>.work-img-container>.work-img{
    height: 100%;
    width: 80%;
}
.work-card:nth-child(7)>.link>.work-img-container>.work-img:hover{
    transform: scale(.95);
}
.work-title{
    margin-top: 10px;
    width: 25vw;
    font-size: 30px;
    color: var(--highlighted-text-color);
}
.work-card:nth-child(7)>.work-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.work-card:nth-child(7)>.work-title-container>.work-title{
    width: 100%;
    font-size: 50px;
    text-align: center;
}
.work-type{
    font-weight: 100;
    font-size: 18px;
}
.work-card:nth-child(7)>.work-title-container>.work-type{
    width: 100%;
    font-size: 20px;
    text-align: center;
}

@media only screen and (min-width: 500px) and (max-width:1024px){
    .work-body{
        display: flex;
        flex-direction: column;
        margin: 10px 20px;
    }
    .hero-para{
        font-size: 20px;
        width: 80%;
        font-weight: 100;
    }
    .work-card{
        margin: 15px 0;
    }
    .work-img-container{
        width: 25vw;
        height: 20vh;
        display: flex;
        align-items: center; 
        justify-content: center;
        overflow: hidden;
    }
    .work-img{
        object-fit: cover;
        min-width: 25vw;
        min-height: 20vh;
    }
    .work-title{
        margin-top: 5px;
        width: 25vw;
        font-size: 18px;
        color: var(--highlighted-text-color);
    }
    .work-type{
        font-weight: 100;
        font-size: 14px;
    }
    .work-card:nth-child(7)>.link>.work-img-container{
        width: 100%;
        height: 35vh;
    }
    .work-card:nth-child(7)>.work-title-container>.work-title{
        width: 100%;
        font-size: 25px;
        text-align: center;
    }
    .work-card:nth-child(7)>.work-title-container>.work-type{
        width: 100%;
        font-size: 16px;
        text-align: center;
    }

}


@media only screen and (min-width: 0px) and (max-width: 500px){
    .work-body{
        display: flex;
        flex-direction: column;
        margin: 10px 10px;
    }
    .hero-para{
        font-size: 20px;
        width: 80%;
        font-weight: 100;
    }
    .work-img-container{
        width: 45vw;
        height: 20vh;
        display: flex;
        align-items: center; 
        justify-content: center;
        overflow: hidden;
    }
    .work-img{
        object-fit: cover;
        min-width: 45vw;
        min-height: 20vh;
    }
    .work-title{
        margin-top: 5px;
        width: 45vw;
        font-size: 18px;
        color: var(--highlighted-text-color);
    }
    .work-type{
        font-weight: 100;
        font-size: 14px;
        width: 45vw;
    }
    .work-card:nth-child(7)>.link>.work-img-container{
        width: 100%;
        height: 25vh;
    }
    .work-card:nth-child(7)>.link>.work-img-container>.work-img{
        height: 100%;
        width: 95%;
    }
    .work-card:nth-child(7)>.work-title-container>.work-title{
        width: 100%;
        font-size: 25px;
        text-align: center;
    }
    .work-card:nth-child(7)>.work-title-container>.work-type{
        width: 100%;
        font-size: 16px;
        text-align: center;
    }
}