.work-page-container{
    margin: 60px 120px;
}
.work-page-title{
    font-size: 60px;
    color: var(--highlighted-text-color);
}
.work-page-desc{
    width: 30vw;
    margin-top: 20px;
}
.work-page-main-img-container{
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}
.work-page-main-img{
    max-width: 80vw;
    max-height: 80vh;
}
.work-desc-container{
    width: 50vw;
    margin: 40px auto;
    text-align: justify;
}
.more-img-container{
    display: flex;
    margin: 60px;
    overflow: scroll;

}
.more-img{
    height: 40vh;
}

@media only screen and (min-width: 500px) and (max-width:1024px){
    .work-page-container{
        margin: 10px 40px;
    }
    .work-page-title{
        font-size: 30px;
        color: var(--highlighted-text-color);
    }
    .work-page-desc{
        width: 50vw;
        margin-top: 16px;
    }
    .work-desc-container{
        width: 80vw;
        margin: 20px auto;
        text-align: justify;
    }

}

@media only screen and (min-width: 0px) and (max-width: 500px){
    .work-page-container{
        margin: 10px 40px;
    }
    .work-page-title{
        font-size: 30px;
        color: var(--highlighted-text-color);
    }
    .work-page-desc{
        width: 80vw;
        margin-top: 16px;
    }
    .work-desc-container{
        width: 80vw;
        margin: 20px auto;
        text-align: justify;
    }
    .more-img-container{
        display: flex;
        margin: 30px;
        overflow: scroll;
    
    }
    .more-img{
        height: 20vh;
    }
}
